<template>
  <div class="d-none d-print-block">
    <v-divider />
    <v-divider />
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width:50%">{{ $t('invoice.print-dish-name') }}</th>
            <th style="width:10%; text-align: center">
              {{ $t('invoice.print-quantity') }}
            </th>
            <th style="width:20%; text-align: center">
              {{ $t('invoice.print-price') }}
            </th>
            <th style="width:20%; text-align: center">
              {{ $t('invoice.print-price-total') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in entries" :key="index">
            <td>
              {{ $i18n.locale === 'vi' ? item.viName : item.enName }}
            </td>
            <td style="text-align: center;">{{ item.finalQuantity }}</td>
            <td style="text-align: center;">{{ displayNumber(item.price) }}</td>
            <td style="text-align: right;">
              {{ total(item).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-divider />
    <v-divider />
    <v-row style="font-weight: bold; text-transform: uppercase;">
      <v-col cols="8">{{ $t('invoice.total') }}</v-col>
      <v-col cols="4" style="text-align: right;">{{
        invoiceTotal.toLocaleString()
      }}</v-col></v-row
    >
    <div style="margin-top: 4px; text-align: center; font-style: italic;">
      <div style="font-weight: bold;">
        <div>
          {{ org.bankAccount }}
        </div>
        <div>
          {{ $t('invoice.print-instruction') }}
        </div>
      </div>
      <div>
        {{ $t('invoice.print-thank-you') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InvoiceListItemPrint',

  props: {
    entries: Array,
    invoiceTotal: Number,
    orgCode: String
  },

  computed: {
    ...mapState('common', ['restaurantInfo']),

    org() {
      return this.restaurantInfo.find(x => x.orgCode === this.orgCode)
    }
  },

  methods: {
    total(item) {
      return item.price * item.finalQuantity
    },

    displayNumber(number) {
      return new Intl.NumberFormat('en-gb', {
        notation: 'compact',
        compactDisplay: 'short'
      }).format(number)
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  width: 100%;
}

th {
  padding: 0 !important;
  color: black !important;
}

td {
  padding: 0 !important;
  color: black !important;
}
</style>
