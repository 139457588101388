<template>
  <v-row dense>
    <v-col cols="12"><v-divider color="grey"/></v-col>
    <v-col cols="9"
      ><span class="font-weight-medium">{{
        $i18n.locale === 'vi' ? item.viName : item.enName
      }}</span></v-col
    >
    <v-col class="d-flex justify-end" cols="3">
      <span class="font-weight-medium">{{
        item.price.toLocaleString()
      }}</span></v-col
    >
    <v-col cols="9"
      ><span class="font-italic">{{
        $t('invoice.entry-quantity', { qt: quantity })
      }}</span></v-col
    >
    <v-col class="d-flex justify-end" cols="3">
      <span class="font-weight-medium primary--text">{{
        total.toLocaleString()
      }}</span></v-col
    >
  </v-row>
</template>

<script>
import { displayQuantity } from '@/utils'

export default {
  name: 'InvoiceListItemEntry',

  props: {
    item: Object
  },

  computed: {
    total() {
      return this.item.price * this.item.finalQuantity
    },

    quantity() {
      return displayQuantity(this.item.finalQuantity, this.item.inKilogram)
    }
  }
}
</script>

<style scoped></style>
