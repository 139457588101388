<template>
  <div>
    <v-card
      :color="$helper.setBgColor(invoice.status)"
      @click="
        $auth.isAuthenticated
          ? $mitt.emit('openViewOrder', { item: invoice, type: 'invoice' })
          : ''
      "
    >
      <v-card-actions>
        <BaseIconButton
          icon-name="money"
          @action="$emit('openConfirmPayment', invoice)"
        />
        <v-spacer />
        <BaseIconButton
          v-if="$auth.isAuthenticated"
          icon-name="edit"
          @action="$emit('openUpdateInvoice', invoice)"
        />
      </v-card-actions>
      <div :ref="`invoice${invoice.invoiceNum}`">
        <v-card-subtitle class="d-none d-print-block invoice--header">{{
          org.name
        }}</v-card-subtitle>
        <v-card-subtitle class="d-none d-print-block invoice--sub-header"
          >Ship: {{ org.phone }}</v-card-subtitle
        >
        <InvoiceInfo :invoice="invoice" />
      </div>
      <v-card-actions v-if="invoice.status !== 'cancelled'">
        <BaseIconButton
          icon-name="cancelledPayment"
          @action="openCancelInvoice"
        />
        <v-spacer />
        <BaseIconButton
          v-if="showPrint"
          icon-name="print"
          @action="printInvoice"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import InvoiceInfo from './InvoiceInfo'
import { mapState } from 'vuex'
import { isMobile } from 'mobile-device-detect'
import { makePrint } from '@/utils'

export default {
  name: 'InvoiceListItem',

  components: {
    InvoiceInfo
  },

  props: {
    invoice: Object
  },

  computed: {
    ...mapState('common', ['restaurantInfo']),

    showPrint() {
      return !isMobile
    },

    org() {
      return this.restaurantInfo.find(x => x.orgCode === this.invoice.orgCode)
    }
  },

  methods: {
    openCancelInvoice() {
      const item = {
        type: 'cancelInvoice',
        data: this.invoice,
        confirmTitle: 'invoice.cancel-title',
        confirmContent: this.$t('invoice.cancel-text', {
          invoice: `${this.invoice.tableName} - ${this.invoice.customerName}`
        })
      }
      this.$mitt.emit('openConfirmDialog', item)
    },

    showQR() {
      this.$emit('showInvoiceQR', this.invoice)
    },

    printInvoice() {
      const bill = this.$refs[`invoice${this.invoice.invoiceNum}`].innerHTML
      makePrint(bill, 2)
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice {
  text-align: center;
  padding-bottom: 0;
  &--header {
    @extend .invoice;
    font-weight: bold;
    text-transform: uppercase;
  }
  &--sub-header {
    @extend .invoice--header;
    padding-top: 0;
  }
}
</style>
