<template>
  <div>
    <v-row dense class="text-center font-weight-medium mb-3">
      <v-col
        v-for="category in summaryCategories"
        :key="category.status"
        cols="12"
        sm="4"
        :class="category.class"
        ><span>{{ $t(`invoice.${category.status}`) }}</span
        ><br v-if="$vuetify.breakpoint.smOnly" />
        <span v-else> : </span
        ><span>{{ totalAmount(category.status) }}</span></v-col
      >
    </v-row>
    <BaseList
      type="invoice"
      :searchTerm.sync="search"
      :page.sync="page"
      :totalPages="totalPages"
      :pagination="isAdmin ? true : false"
      :noData="isAdmin ? invoiceCount === 0 : invoiceCountCashier === 0"
      :isLoading="
        isAdmin ? invoiceCount === null : invoiceCountCashier === null
      "
      noDataText="invoice.no-invoice-found"
      :filterCount="isAdmin ? 2 : 1"
      @doSearch="searchInvoice()"
      @update:page="isAdmin ? fetchInvoices() : ''"
    >
      <template v-slot:filter1>
        <BaseFilter
          label="invoice.status-dropdown"
          no-data="invoice.no-status"
          v-model="status"
          :items="type"
          item-text="status"
          item-value="key"
          @change="searchInvoice()"
        />
      </template>
      <template v-if="isAdmin" v-slot:filter2>
        <BaseDatePicker
          range
          showTypeSelection
          :monthPicker.sync="selectMonthPicker"
          v-model="date"
          :label="
            selectMonthPicker
              ? 'invoice.month-invoiced'
              : 'invoice.date-invoiced'
          "
          @input="searchInvoice('updateAutoRange')"
        />
      </template>
      <v-row>
        <v-col
          v-for="(invoice, index) in invoicesOnDisplay"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          ><InvoiceListItem
            :invoice="invoice"
            @openUpdateInvoice="openUpdateInvoice"
            @openConfirmPayment="openConfirmPayment"
            @showInvoiceQR="openInvoiceQR"/></v-col
      ></v-row>
    </BaseList>
    <UpdateInvoice
      v-if="updateDialog"
      :dialog.sync="updateDialog"
      :invoice="selectedInvoice"
    />
    <ConfirmPayment
      v-if="paymentDialog"
      :dialog.sync="paymentDialog"
      :invoice="selectedInvoice"
    />
    <InvoiceQR
      v-if="invoiceQRDialog"
      :dialog.sync="invoiceQRDialog"
      :invoice="selectedInvoice"
    />
  </div>
</template>

<script>
import InvoiceListItem from './InvoiceListItem'
import InvoiceService from '@/services/modules/InvoiceService'
import {
  displayErrorMessage,
  hasRole,
  getInvoiceTotal,
  fullTextSearch,
  setAutoRange,
  updateAutoRange
} from '@/utils'
import { mapState } from 'vuex'

const UpdateInvoice = () => import('./UpdateInvoice')
const ConfirmPayment = () => import('./ConfirmPayment')
const InvoiceQR = () => import('./InvoiceQR')

export default {
  name: 'OrderList',

  components: {
    InvoiceListItem,
    UpdateInvoice,
    ConfirmPayment,
    InvoiceQR
  },

  data: () => ({
    selectedInvoice: {},
    updateDialog: false,
    paymentDialog: false,
    invoiceQRDialog: false,
    invoices: [],
    totalPages: 0,
    invoiceCount: null,
    page: 1,
    limit: 12,
    search: '',
    status: '',
    date: null,
    selectMonthPicker: false,
    filteredList: null,
    summaryCategories: [
      { status: 'paid', class: 'green lighten-4 green--text text--darken-3' },
      {
        status: 'pending',
        class: 'amber lighten-4 red--text'
      },
      { status: 'cancelled', class: 'grey lighten-2 grey--text text--darken-3' }
    ]
  }),

  computed: {
    ...mapState('common', ['rangeType', 'selectedDate']),

    type() {
      return [
        { status: this.$t('invoice.pending'), key: 'pending' },
        { status: this.$t('invoice.paid'), key: 'paid' },
        { status: this.$t('invoice.cancelled'), key: 'cancelled' }
      ]
    },

    isAdmin() {
      return hasRole('Admin')
    },

    invoicesOnDisplay() {
      if (this.isAdmin) {
        return this.invoices
      } else {
        const start = (this.page - 1) * this.limit
        const end = this.page * this.limit
        if (Array.isArray(this.filteredList)) {
          return this.filteredList.slice(start, end)
        } else {
          return []
        }
      }
    },

    invoiceCountCashier() {
      if (Array.isArray(this.filteredList)) {
        return this.filteredList.length
      } else {
        return null
      }
    }
  },

  mounted() {
    if (this.rangeType === 'monthly') {
      this.selectMonthPicker = true
    }
    this.date = setAutoRange(this.rangeType, this.selectedDate)
    this.fetchInvoices()
    this.$mitt.on('createInvoice', this.addToList)
    this.$mitt.on('updateInvoice', this.addToList)
    this.$mitt.on('refreshList', this.refreshList)
  },
  beforeDestroy() {
    this.$mitt.off('createInvoice', this.addToList)
    this.$mitt.off('updateInvoice', this.addToList)
    this.$mitt.off('refreshList', this.refreshList)
  },

  watch: {
    updateDialog(val) {
      if (val === false) {
        this.$mitt.emit('showDetails', this.selectedInvoice._id)
      }
    }
  },

  methods: {
    searchInvoice(val) {
      if (val === 'updateAutoRange') {
        updateAutoRange(this.selectMonthPicker, this.date)
      }
      if (this.isAdmin) {
        this.fetchInvoices(true)
      } else {
        const matchText = (text, invoice) => {
          const {
            tableName,
            customerName,
            updatedBy,
            note,
            invoiceNum
          } = invoice
          return fullTextSearch(
            text,
            [tableName, customerName, updatedBy, note],
            [invoiceNum]
          )
        }
        const matchStatus = (status, invoice) => {
          if (status) {
            return invoice.status === status
          } else {
            return true
          }
        }
        this.filteredList = this.invoices.filter(invoice => {
          return (
            matchText(this.search, invoice) && matchStatus(this.status, invoice)
          )
        })
      }
    },

    openUpdateInvoice(invoice) {
      this.selectedInvoice = invoice
      this.updateDialog = true
    },

    openConfirmPayment(invoice) {
      this.selectedInvoice = invoice
      this.paymentDialog = true
    },

    openInvoiceQR(invoice) {
      this.selectedInvoice = invoice
      this.invoiceQRDialog = true
    },

    addToList(invoice) {
      const index = this.invoices.findIndex(x => x.orderId === invoice.orderId)
      this.invoices.splice(index, 1, invoice)
      if (!this.isAdmin) {
        this.searchInvoice()
      }
    },

    fetchInvoices(restart) {
      if (restart) {
        this.page = 1
      }
      const conditions = {
        page: this.page,
        limit: this.limit,
        search: this.search,
        status: this.status,
        byMonth: this.selectMonthPicker
      }

      if (this.search || this.status || this.date) {
        delete conditions.limit
        if (Array.isArray(this.date)) {
          conditions.date = this.date
        }
      }

      if (!this.isAdmin) {
        delete conditions.page
        delete conditions.limit
      }

      InvoiceService.getList(conditions)
        .then(res => {
          const { docs, totalPages, totalDocs } = res.data
          this.invoices = docs
          this.filteredList = docs
          if (this.isAdmin) {
            this.totalPages = totalPages
            this.invoiceCount = totalDocs
          }
        })
        .catch(err => displayErrorMessage(err))
    },

    refreshList() {
      this.fetchInvoices(true)
    },

    filterPaymentStatus(status) {
      const listByStatus = this.invoices.filter(
        invoice => invoice.status === status
      )
      return listByStatus
    },

    totalAmount(status) {
      const list = this.filterPaymentStatus(status)
      let total = 0
      if (status === 'paid') {
        const sumFunc = (acc, item) => {
          return acc + item.receivedAmount
        }
        total += list.reduce(sumFunc, 0)
      } else {
        list.forEach(invoice => {
          const totalInvoice = getInvoiceTotal(invoice.entries)
          total += totalInvoice
        })
      }
      return total.toLocaleString()
    }
  },

  sockets: {
    refreshInvoiceList() {
      this.fetchInvoices(true)
    }
  }
}
</script>

<style scoped></style>
