<template>
  <div>
    <v-card-title class="pt-0"
      ><span>{{ invoice.tableName }}</span
      ><v-spacer /><span :class="statusStyle">{{
        $t(`invoice.${status}`)
      }}</span></v-card-title
    >
    <v-card-text class="text-body-1 black--text pb-0">
      <BaseEntry
        class="no-print"
        label="invoice.customer"
        :content="invoice.customerName"
      />
      <BaseEntry label="invoice.invoice-num" :content="invoice.invoiceNum" />
      <BaseEntry
        label="invoice.check-in"
        :content="$helper.formattedDate(invoice.checkinAt)"
      />
      <BaseEntry
        label="invoice.check-out"
        :content="$helper.formattedDate(invoice.createdAt)"
      />
      <BaseEntry label="invoice.creator" :content="invoice.updatedBy" />
      <BaseEntry
        v-if="invoice.note"
        class="no-print"
        label="common.note"
        :content="invoice.note"
      />
      <div class="mt-4 text-uppercase font-weight-medium no-print">
        <v-row dense align="center" class="primary--text">
          <v-col cols="7">{{ $t('invoice.total') }}</v-col>
          <v-col cols="5" class="d-flex justify-end text-h6">
            {{ invoiceTotal.toLocaleString() }}</v-col
          >
        </v-row>
        <v-row
          v-if="invoice.status === 'paid'"
          dense
          align="center"
          :class="`${statusStyle}`"
        >
          <v-col cols="7"
            ><div>{{ $t('invoice.received') }}</div>
            <div>({{ $t(`invoice.${paymentMethod}`) }})</div></v-col
          >
          <v-col cols="5" class="d-flex justify-end text-h6">
            {{ invoice.receivedAmount.toLocaleString() }}</v-col
          >
        </v-row>
      </div>
      <div
        class="no-print d-flex pt-2 pb-4 justify-center"
        @mousedown.stop.prevent
        @touchstart.stop
        @click.stop="$mitt.emit('setState', invoice._id)"
      >
        <div
          class="mr-2 font-italic blue--text text--darken-3 font-weight-medium"
        >
          {{ showHideText }}
        </div>
        <v-icon color="blue darken-3">{{ showHideIcon }}</v-icon>
      </div>
      <div v-show="isShowingDetails" class="pb-4 no-print">
        <InvoiceListItemEntry
          v-for="(entry, index) in entries"
          :key="index"
          :item="entry"
        />
      </div>
      <InvoiceListItemPrint
        :entries="entries"
        :invoiceTotal="invoiceTotal"
        :orgCode="invoice.orgCode"
      />
    </v-card-text>
  </div>
</template>

<script>
import InvoiceListItemEntry from './InvoiceListItemEntry'
import InvoiceListItemPrint from './InvoiceListItemPrint'
import { getInvoiceTotal } from '@/utils'

export default {
  name: 'InvoiceInfo',

  components: { InvoiceListItemEntry, InvoiceListItemPrint },

  props: {
    invoice: Object
  },

  data: () => ({
    isShowingDetails: false
  }),

  computed: {
    entries() {
      return this.invoice.entries
    },

    invoiceTotal() {
      return getInvoiceTotal(this.invoice.entries)
    },

    status() {
      return this.invoice.status
    },

    statusStyle() {
      const { status } = this.invoice
      let color
      if (status === 'pending') {
        color = 'red--text'
      } else if (status === 'paid') {
        color = 'green--text text--darken-3'
      } else {
        color = 'grey--text text--darken-3'
      }
      return `${color} no-print`
    },

    paymentMethod() {
      return this.invoice.paymentMethod
    },

    showHideText() {
      if (this.isShowingDetails) {
        return this.$t('invoice.hide-details')
      } else {
        return this.$t('invoice.show-details')
      }
    },

    showHideIcon() {
      if (this.isShowingDetails) {
        return '$hide'
      } else {
        return '$show'
      }
    }
  },

  mounted() {
    this.$mitt.on('setState', this.setState)
    this.$mitt.on('showDetails', this.showDetails)
  },
  beforeDestroy() {
    this.$mitt.off('setState', this.setState)
    this.$mitt.off('showDetails', this.showDetails)
  },

  methods: {
    setState(invoiceId) {
      if (invoiceId === this.invoice._id) {
        this.isShowingDetails = !this.isShowingDetails
      } else if (this.isShowingDetails) {
        this.isShowingDetails = false
      }
    },

    showDetails(invoiceId) {
      if (invoiceId === this.invoice._id && !this.isShowingDetails) {
        this.isShowingDetails = true
      } else if (invoiceId !== this.invoice._id && this.isShowingDetails) {
        this.isShowingDetails = false
      }
    }
  }
}
</script>

<style scoped></style>
